<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">단말기 상세</h2>
    </div>

    <div class="detail-box mt-s">
      <div class="map-area justify-center items-center" ref="mapAreaWrapper">
        <div class="flex relative justify-center items-center">
          <img :src="mapInfo.fileContent" alt="" ref="mapImage" />
          
          <div class="absolute" v-if="dispPointX && dispPointY" :style="{left:dispPointX + 'px', top:dispPointY + 'px'}">
            <span :class="deviceInfo.deviceStatus == 'regist' ? (deviceInfo.detectCount > 0 ? 'ic-alarm-state2' : 'ic-alarm-state1') : 'ic-alarm-state4'"><i class="icon-img ic-loading-signal-only"></i></span>
          </div>
        </div>
      </div>
      <div class="detail-info">
        <div class="detail-content">
          <div class="title-area">
            <h3 class="title-b">{{deviceInfo.deviceName}}</h3>
            <div class="flag-item round" :class="deviceInfo.deviceStatus === 'regist'? 'green' : 'red'">{{deviceStatusList[deviceInfo.deviceStatus]}}</div>
          </div>

          <dl class="detail-content-item">
            <dt class="title">단말 연락처</dt>
            <dt class="value">{{ deviceInfo.deviceMsisdn | phoneNumber }}</dt>
          </dl>

          <dl class="detail-content-item">
            <dt class="title">단말 ID</dt>
            <dt class="value">innertron-{{deviceInfo.deviceID}}</dt>
          </dl>

          <dl class="detail-content-item">
            <dt class="title">펌웨어</dt>
            <dt class="value">
              v{{deviceInfo.deviceFWVersion}}
              <span class="txt-update cursor-pointer" v-if="(getUserRole === 'master' || getUserRole === 'admin') && deviceInfo.deviceStatus == 'regist'" @click="openUpdateCommandSend('firmware')">
                <i class="icon-img ic-system-update"></i>
                업데이트
              </span>
            </dt>
          </dl>
          <dl class="detail-content-item">
            <dt class="title">Wifi DB 버전</dt>
            <dt class="value">
              v{{deviceInfo.deviceWifiVersion}}
              <span class="txt-update cursor-pointer" v-if="(getUserRole === 'master' || getUserRole === 'admin') && deviceInfo.deviceStatus == 'regist'" @click="openUpdateCommandSend('wifi')">
                <i class="icon-img ic-system-update"></i>
                업데이트
              </span>
            </dt>
          </dl>
          <dl class="detail-content-item">
            <dt class="title">설치위치</dt>
            <dt class="value">{{deviceInfo.siteName}} > {{deviceInfo.mapName}}</dt>
          </dl>
          <dl class="detail-content-item">
            <dt class="title">단말기 메모</dt>
            <dt class="value">{{deviceInfo.deviceMemo}}</dt>
          </dl>
        </div>
        <div class="side-content">
          <div class="row-top">
            <p class="side-txt-a">단말기 정보 업데이트</p>
            <p class="side-txt-b flex justify-end items-center">
              <TimeDiff :timeStr="deviceInfo.aliveDate"></TimeDiff>
              <span class="mx-2">({{deviceInfo.aliveDate}})</span>
            </p>

            <button type="button" class="element-btn bg-white size-sm mr-5" @click="refreshDeviceInfo()">
              <i class="icon-img ic-info-reload"></i>
              정보 업데이트
            </button>

            <button type="button" class="element-btn bg-white size-sm" @click="rebootDevice()" :disabled="deviceInfo.deviceStatus != 'regist'">
              <i class="icon-img ic-info-reload"></i>
              단말 재시작
            </button>

            <p class="text-left mt-5">
              <button type="button" class="element-btn bg-white size-sm mr-5" @click="initDeviceDetect()" v-if="getUserRole === 'master'">
                <i class="icon-img ic-filter-delete"></i>
                탐지/차단내역 초기화
              </button>
            </p>
          </div>
          <div class="row-bottom">
            <div>
              <button type="button" class="element-btn bg-purple round" @click="moveEventAdmin()">
                <i class="icon-img ic-btn-notification"></i>
                이벤트 알림보기
              </button>
              <br />
              <br />
              <button type="button" class="element-btn bg-sky round" @click="moveDeviceLogAdmin()">
                <i class="icon-img ic-btn-notification"></i>
                단말 로그 보기
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>


    <!-- 탐지 & 차단 관리 -->
    <div class="title-area mt-sm">
        <h2 class="title-b">탐지 불법 카메라 목록</h2>
    </div>
    <div class="table-a mt-2xs">
        <table>
            <colgroup>
                <col style="width:5%">
                <col style="width:20%">
                <col style="width:15%" :span="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member' ? 5 : 4">
            </colgroup>
            <thead>
            <tr>
                <th scope="col">No.</th>
                <th scope="col">MAC 주소</th>
                <th scope="col">탐지카메라 유형</th>
                <th scope="col">반경(m)</th>
                <th scope="col">탐지시간</th>
                <th scope="col">상태</th>
                <th scope="col" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">차단</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(dItem, idx) in detectList" :key="idx">
                <td>{{ detectTotalCount - idx - (detectLimit * ( detectCurrentPage - 1 )) }}</td>
                <td>{{dItem.detectMacAddress}}</td>
                <td>{{cameraType[dItem.detectType]}}</td>
                <td>{{dItem.detectDistance | makeComma}}m</td>
                <td>{{dItem.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                <td><div class="flag-item gold">탐지</div></td>
                <td v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">
                  <button type="button" :disabled="deviceInfo.deviceStatus != 'regist' || isBlockAction(dItem.detectMacAddress)" class="element-btn bg-red size-sm round" @click="chgDetectBlockDevice(dItem)">차단</button>
                  <!-- <button type="button" :disabled="isBlockAction(dItem.detectMacAddress)" class="element-btn bg-red size-sm round" @click="chgDetectBlockDevice(dItem)">차단</button> -->
                </td>
            </tr>
            </tbody>
        </table>

        <paging-component :totalCount="detectTotalCount" :limit="detectLimit" :currentPage="detectCurrentPage" @chgCurrentPage="detectChgCurrentPage"></paging-component>
    </div>

    <div class="title-area mt-sm">
        <h2 class="title-b">차단 불법 카메라 목록</h2>
        <button type="button" :disabled="deviceInfo.deviceStatus != 'regist'" class="element-btn bg-navy size-xs mx-6 mt-2" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'" @click="displaySendBlockListDialog()">차단 목록 전송</button>
    </div>

    <div class="table-a mt-2xs">
        <table>
            <colgroup>
                <col style="width:5%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:8%">
                <col style="width:10%" span="2">
                <col style="width:9%" :span="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member' ? 4 : 3">
                <col style="width:3%">
                <col style="width:3%" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">
            </colgroup>
            <thead>
            <tr>
                <th scope="col">No.</th>
                <th scope="col">MAC 주소</th>
                <th scope="col">탐지카메라 유형</th>
                <th scope="col">반경(m)</th>
                <th scope="col">최초탐지시간</th>
                <th scope="col">차단일</th>
                <th scope="col">상태</th>
                <th scope="col">조치사항</th>
                <th scope="col" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">차단</th>
                <th scope="col">기기이력</th>
                <th scope="col">수정</th>
                <th scope="col" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">삭제</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(bItem, idx) in blockList" :key="idx">
                <td>{{ blockTotalCount - idx - (blockLimit * ( blockCurrentPage - 1 )) }}</td>
                <td>{{bItem.detectMacAddress}}</td>
                <td>{{cameraType[bItem.detectType]}}</td>
                <td>{{bItem.detectDistance | makeComma}}m</td>
                <td>{{bItem.firstDetectDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                <td>{{bItem.blockDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                <td>
                  <div v-if="bItem.detectStatus == 'clear' && isDetectAction(bItem.detectMacAddress)" class="flag-item gold">탐지</div>
                  <div v-else class="flag-item" :class="bItem.detectStatus === 'block'? 'red' : 'navy'">{{detectStatus[bItem.detectStatus]}}</div>
                </td>
                <td>{{blockActionList[bItem.blockAction]}}</td>
                <td v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">
                  <button type="button" :disabled="deviceInfo.deviceStatus != 'regist'" class="element-btn size-sm round" :class="bItem.detectStatus === 'block'? 'bg-dark-gray' : 'bg-red'" @click="chgBlockStatusDevice(bItem)">{{bItem.detectStatus === 'block'? '해제' : '차단'}}</button>
                </td>
                <td><a @click="dispBlockHistory(bItem.blockGuid, bItem.detectMacAddress)" class="link-a cursor-pointer">이력보기</a></td>
                <td>
                    <button type="button" class="btn-modify" @click="chgActionDevice(bItem)">
                        <i class="icon-img ic-txt-list-edit"></i>
                        <span class="for-a11y">수정</span>
                    </button>
                </td>
                <td v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'">
                    <button type="button" class="btn-modify" @click="dispDeleteBlockListDialog(bItem.blockGuid)">
                        <i class="icon-img ic-txt-list-del"></i>
                        <span class="for-a11y">삭제</span>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <paging-component :totalCount="blockTotalCount" :limit="blockLimit" :currentPage="blockCurrentPage" @chgCurrentPage="blockChgCurrentPage"></paging-component>
    </div>


    <div class="title-area mt-sm">
        <h2 class="title-b">탐지 내역 목록</h2>
        <button type="button" class="element-btn bg-purple size-xs mx-6 mt-2" v-if="getUserRole === 'master' || getUserRole === 'admin' || getUserRole === 'member'" @click="excelDownload()">다운로드</button>
    </div>
    <div class="table-a mt-2xs">
        <table>
            <colgroup>
                <col style="width:5%">
                <col style="width:20%">
                <col style="width:15%" span="5">
            </colgroup>
            <thead>
            <tr>
                <th scope="col">No.</th>
                <th scope="col">MAC 주소</th>
                <th scope="col">탐지카메라 유형</th>
                <th scope="col">반경(m)</th>
                <th scope="col">설치위치</th>
                <th scope="col">탐지시간</th>
                <th scope="col">최초탐지시간</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(dItem, idx) in detectHistoryList" :key="idx">
                <td>{{ detectHistoryTotalCount - idx - (detectHistoryLimit * ( detectHistoryCurrentPage - 1 )) }}</td>
                <td>{{ dItem.detectMacAddress}}</td>
                <td>{{cameraType[dItem.detectType]}}</td>
                <td>{{ dItem.detectDistance | makeComma}}m</td>
                <td>[{{ dItem.siteName }}] [{{ dItem.mapName }}] [{{ dItem.deviceMemo }}]</td>
                <td>{{ dItem.detectDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                <td>{{ dItem.firstDetectDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
            </tr>
            </tbody>
        </table>

        <paging-component :totalCount="detectHistoryTotalCount" :limit="detectHistoryLimit" :currentPage="detectHistoryCurrentPage" @chgCurrentPage="detectHistoryChgCurrentPage"></paging-component>
    </div>

    <!-- 업데이트 다이얼로그 START -->
    <!-- <div class="module-dimmed" v-if="showUpdateDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">{{updateType === 'firmware' ? '펌웨어' : 'WiFi DB' }}를 업데이트 합니다.<br>업데이트 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeUpdateCommandSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="excuteUpdateCommandSend()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeUpdateCommandSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div> -->
    <div class="module-dimmed" v-if="showUpdateDialog">
      <div class="module-dialog dialog-lg">
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="px-10">
              <p class="text-center text-4xl text-gray-700 font-black">{{updateType === 'firmware' ? '펌웨어' : 'WiFi DB' }} 업데이트</p>

              <p class="text-xl text-gray-700 font-black mt-10">단말기 정보</p>

              <div class="grid grid-cols-2 gap-5 mt-5">
                <div>
                  <p class="text-lg font-bold text-gray-400">단말 ID</p>
                  <p class="text-lg text-gray-700 mt-3">innertron-{{deviceInfo.deviceID}}</p>
                </div>
                
                <div>
                  <p class="text-lg font-bold text-gray-400">펌웨어 버전</p>
                  <p class="text-lg text-gray-700 mt-3">v{{ updateType === 'firmware' ? deviceInfo.deviceFWVersion : deviceInfo.deviceWifiVersion }}</p>
                </div>
              </div>

              <div class="mt-6">
                <div>
                  <p class="text-lg font-bold text-gray-400">설치 위치</p>
                  <p class="text-lg text-gray-700 mt-3">{{deviceInfo.siteName}} > {{deviceInfo.mapName}}</p>
                </div>
              </div>

              <div class="grid grid-cols-3 gap-5 mt-6">
                <div>
                  <p class="text-lg font-bold text-gray-400">상태</p>
                  <p class="text-lg mt-3" :class="deviceInfo.deviceStatus === 'regist'? 'text-green-700' : 'text-red-700'">{{deviceStatusList[deviceInfo.deviceStatus]}}</p>
                </div>
                
                <div>
                  <p class="text-lg font-bold text-gray-400">최근 확인</p>
                  <p class="text-lg text-gray-700 mt-3">{{deviceInfo.aliveDate | moment('YYYY-MM-DD HH:mm:ss') }}</p>
                </div>

                <div>
                  <p class="text-lg font-bold text-gray-400">단말기 연락처</p>
                  <p class="text-lg text-gray-700 mt-3">{{ deviceInfo.deviceMsisdn | phoneNumber }}</p>
                </div>
              </div>

              <p class="text-center text-xl text-gray-700 font-black mt-10">{{updateType === 'firmware' ? '펌웨어' : 'Wifi DB'}} 파일</p>

              <div class=" w-full border border-solid border-gray-300 py-5 flex justify-center items-center mt-3 rounded-lg">
                <i class="icon-img ic-firmware mx-5"></i>
                <span class="link-firmware">{{updateType === 'firmware' ? firmwareInfo.device.fileOrgName : firmwareInfo.wifi.fileOrgName}}</span>
              </div>

              <div class="text-center mt-5">
                <p class="text-gray-700">{{updateType === 'firmware' ? '펌웨어' : 'Wifi DB'}} 버젼 : v{{updateType === 'firmware' ? firmwareInfo.device.firmwareVersion : firmwareInfo.wifi.firmwareVersion}}</p>
                <p class="text-gray-700">파일 용량 : {{updateType === 'firmware' ? firmwareInfo.device.fileSize : firmwareInfo.wifi.fileSize  | byteToString}}</p>
              </div>


            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeUpdateCommandSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="excuteUpdateCommandSend()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeUpdateCommandSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 업데이트 다이얼로그 END -->


    <!-- 단말재시작 다이얼로그 START -->
    <div class="module-dimmed" v-if="showResetDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">단말기를 재시작 합니다.<br>재시작 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeResetCommandSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="excuteResetCommandSend()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeResetCommandSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 단말재시작 다이얼로그 END -->

    <!-- 차단(해제) 확인 다이얼로그 START -->
    <div class="module-dimmed" v-if="showBlockConfirmDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="title">탐지된 카메라를 {{blockActionInfo.detectStatus === 'block' ? '해제' : '차단'}}하시겠습니까?</p>
            <div class="table-b mt-40">
              <table>
                <colgroup>
                  <!-- <col style="width:100px"> -->
                  <col style="width:auto">
                  <col style="width:100px">
                  <col style="width:140px">
                </colgroup>
                <thead>
                <tr>
                  <!-- <th scope="col" class="align-left">탐지카메라ID</th> -->
                  <th scope="col">MAC 주소</th>
                  <th scope="col">반경(m)</th>
                  <th scope="col">탐지시간</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <!-- <td class="align-left">{{blockActionInfo.deviceID}}</td> -->
                  <td>{{blockActionInfo.detectMacAddress}}</td>
                  <td>{{blockActionInfo.detectDistance | makeComma}}m</td>
                  <td>{{blockActionInfo.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeBlockConfirmDialog()">취소</button>
          <button v-if="blockActionInfo.detectStatus==null" type="button" class="element-btn bg-blue size-lg" @click="chgDetectBlockDeviceAction()">확인</button>
          <button v-else type="button" class="element-btn bg-navy size-lg" @click="chgBlockStatusDeviceAction()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeBlockConfirmDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단(해제) 확인 다이얼로그 END -->

    <!-- 차단(해제) 완료 다이얼로그 START-->
    <div class="module-dimmed" v-if="showBlockCompleteDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="title">불법 카메라 {{blockActionInfo.detectStatus === 'block' ? '해제가' : '차단이'}} 완료되었습니다.</p>
            <div class="table-b mt-40">
              <table>
                <colgroup>
                  <!-- <col style="width:100px"> -->
                  <col style="width:auto">
                  <col style="width:200px">
                </colgroup>
                <thead>
                <tr>
                  <!-- <th scope="col" class="align-left">탐지카메라ID</th> -->
                  <th scope="col">MAC 주소</th>
                  <th scope="col">{{blockActionInfo.detectStatus === 'block' ? '해제' : '차단'}}시간</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <!-- <td class="align-left">6587fddb</td> -->
                  <td>{{blockActionInfo.detectMacAddress}}</td>
                  <td>{{blockActionInfo.blockDate == null ? (blockActionInfo.clearDate == null ? blockActionInfo.insertDate : blockActionInfo.clearDate) : blockActionInfo.blockDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-blue size-lg"  @click="closeBlockCompleteDialog()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeBlockCompleteDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단(해제) 완료 다이얼로그 END -->

    <!-- 조치사항 다이얼로그 START-->
    <div class="module-dimmed" v-if="showActionDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">불법 카메라 조치사항</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="detail-group">
              <div class="detail-item">
                <span class="detail-label">탐지시간</span>
                <div class="detail-info">{{blockActionInfo.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">불법카메라 MAC주소</span>
                <div class="detail-info">{{blockActionInfo.detectMacAddress}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">반경(m)</span>
                <div class="detail-info">{{blockActionInfo.detectDistance | makeComma}}m</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">불법 카메라 상태</span>
                <div class="detail-info">
                  <span>{{detectStatus[blockActionInfo.detectStatus]}}</span>
                  <div class="element-switch mx-3">
                    <input class="checkbox-input" @change="checkBlockStatus()" type="checkbox" id="detectStatus" v-model="editDetectStatus" :disabled="getUserRole === 'agent' ? true : deviceInfo.deviceStatus != 'regist' ? true: false">
                    <label class="checkbox-label" for="detectStatus"><span class="for-a11y">detectStatus</span></label>
                  </div>
                </div>


              </div>
              <div class="detail-item">
                <span class="detail-label">{{blockActionInfo.detectStatus === 'block' ? '차단' : '해제'}}일</span>
                <div class="detail-info">{{blockActionInfo.blockDate == null ? blockActionInfo.clearDate : blockActionInfo.blockDate | moment('yyyy-MM-DD HH:mm:ss')}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">조치내역</span>
                <div class="detail-form">
                  
                  <t-rich-select
                    :options="blockActionList"
                    class="border border-solid rounded-md py-1 text-gray-700 border-gray-300 outline-none w-52"
                    textAttribute="name"
                    valueAttribute="id"
                    :hideSearchBox="true"
                    v-model="blockAction"
                  >
                  </t-rich-select>

                </div>
              </div>
              <div class="detail-item">
                <span class="detail-label">메모</span>
                <div class="detail-form full-type">
                  <div class="table-c mt-xs">
                    <table  style="display:block;max-height:150px;overflow-y:auto;">
                      <colgroup>
                        <col style="width:120px">
                        <col style="width:100px">
                        <col style="width:auto">
                      </colgroup>
                      <thead>
                      <tr>
                        <th scope="col">날짜</th>
                        <th scope="col">등록ID</th>
                        <th scope="col">메모</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(mItem, idx) in blockMemoList" :key="idx">
                        <td>{{mItem.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                        <td>{{mItem.actorID}}</td>
                        <td>{{mItem.blockMemo}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-20">
                    <textarea name="" class="element-textarea" placeholder="메모 입력" v-model="blockMemo"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeActionDevice()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="submitActionDevice()">저장</button>
        </div>
        <button type="button" class="btn-close" @click="closeActionDevice()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 조치사항 다이얼로그 END -->

    <!-- 차단이력 다이얼로그 START -->
    <div class="module-dimmed" v-if="showBlockHistoryDialog">
      <div class="module-dialog dialog-lg">
        <div class="dialog-header">
          <h2 class="dialog-title">기기 차단이력</h2>
          <p class="desc-group">
            <span class="desc-item txt-blue">MAC 주소</span>
            <span class="desc-item txt-blue">{{historyMacAddress}}</span>
          </p>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="table-a">
              <table>
                <colgroup>
                  <col style="width:30px">
                  <col style="width:180px">
                  <col style="width:120px">
                  <col style="width:auto">
                  <col style="width:100px">
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">일시</th>
                  <th scope="col">액션</th>
                  <th scope="col">조치사항 메모</th>
                  <th scope="col">실행계정ID</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(hItem, idx) in blockHistoryList" :key="idx">
                  <td>{{blockHistoryList.length - idx}}</td>
                  <td>{{hItem.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
                  <td>불법카메라 {{detectStatus[hItem.blockType]}}</td>
                  <td>{{hItem.blockAction==='new'? '차단등록' : blockActionList[hItem.blockAction]}}</td>
                  <td>{{hItem.actorID}}</td>
                </tr>
                
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-blue size-lg" @click="closeBlockHistory()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeBlockHistory()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단이력 다이얼로그 END -->


    <!-- 차단목록 전송 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSendBlockListDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">{{sendBlockListCount}} 개의 차단 목록을 전송 합니다.<br>전송 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeSendBlockListDialog()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="submitSendBlockListDialog()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeSendBlockListDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단목록 전송 다이얼로그 END -->


    <!-- 차단목록 삭제 다이얼로그 START -->
    <div class="module-dimmed" v-if="showDeleteBlockListDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">차단 내역을 삭제 합니다.<br>삭제 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeDeleteBlockListDialog()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="submitDeleteBlockListDialog()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeDeleteBlockListDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단목록 전송 다이얼로그 END -->





    <!-- 차단목록 전송 다이얼로그 START -->
    <div class="module-dimmed" v-if="showInitDetectListDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">탐지/차단 목록을 초기화 합니다.<br>초기화 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeInitDetectListDialog()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="submitInitDetectListDialog()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeInitDetectListDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 차단목록 전송 다이얼로그 END -->

    


  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { requestApi, requestGetDownload } from "@/utils/api"
import TimeDiff from "@/components/elements/TimeDiff"
import PagingComponent from "@/components/elements/paging"

import {deviceStatusList, detectStatus, cameraType, blockActionList} from "@/references/config"

export default {
  created () {
    if(this.$route.query.guid == '' || this.$route.query.guid == null) {
      console.log("???")
    }
    else {
      this.initDevice()
      // this.deviceLoop = setInterval(() => {
      //   this.initDevice()
      // }, 60000)
    }
  },
  destroyed () {
    if(this.deviceLoop != null) clearInterval(this.deviceLoop)
  },
  components: {
    TimeDiff,
    PagingComponent
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid","getEventTimestamp","getOnlineDevice","getOfflineDevice"])
  },
  data () {
    return {
      deviceStatusList,
      detectStatus,
      cameraType,
      deviceInfo: {},
      mapInfo: {},

      detectLimit: 10,
      detectCurrentPage: 1,
      detectTotalCount: 0,
      detectList: [],

      blockLimit: 10,
      blockCurrentPage: 1,
      blockTotalCount: 0,
      blockList: [],

      detectHistoryLimit: 10,
      detectHistoryCurrentPage: 1,
      detectHistoryTotalCount: 0,
      detectHistoryList: [],

      showUpdateDialog: false,
      updateType: 'firmware',

      deviceLoop: null,

      blockActionInfo: {},
      showBlockConfirmDialog: false,
      showBlockCompleteDialog: false,

      showActionDialog: false,
      blockActionList, 
      blockAction: 'none',
      
      editDetectStatus: false,
      blockMemo: '',
      blockMemoList: [],

      showBlockHistoryDialog: false,
      historyMacAddress: '',
      blockHistoryList: [],

      showResetDialog: false,

      showSendBlockListDialog: false,
      sendBlockListCount: 0,

      showInitDetectListDialog: false,

      showDeleteBlockListDialog: false,
      deleteBlockGuid: '',

      dispPointX: 0,
      dispPointY: 0,

      firmwareInfo: {
        "device": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        },
        "wifi": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        }
      },
    }
  },
  methods: {
    ...mapActions(["userLogoutAction","chgIsAlert"]),
    initDevice () {
      this.getDeviceInfo(() => {
        this.getDetectList()
        this.getBlockList()
        this.getDetectHistoryList()
      })
    },

    isBlockAction (macAddress) {
      let retVal = false;
      retVal = this.blockList.filter(x => x.detectMacAddress == macAddress && x.detectStatus == 'block').length > 0 ? true : false

      return retVal
    },
    isDetectAction (macAddress) {
      let retVal = false;
      retVal = this.detectList.filter(x => x.detectMacAddress == macAddress).length > 0 ? true : false

      return retVal
    },
    async openUpdateCommandSend (type) {
      if(this.getUserRole === 'member' || this.getUserRole === 'agent') {
        // this.chgIsAlert({status:true,string:'권한이 없습니다.'})
        return;
      }

      if(this.deviceInfo.deviceMsisdn == "00000000000") {
        this.chgIsAlert({status:true,string:'단말의 착신번호가 확인되지 않았습니다.'})
        
        return;
      }

      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 업데이트 가능 합니다.`})
        return;
      }


      this.getFirmwareInfo("device")
      this.getFirmwareInfo("wifi")

      this.updateType = type
      this.showUpdateDialog = true
    },
    async getLastRestartDeive () {
      let reqObj = {}
      reqObj.target = `/device/chkRestar/${this.deviceInfo.deviceGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        // console.log(result.msg.retData)
        return result.msg.retData
      }
      else return 0
    },
    async getFirmwareInfo (firmwareType) {
      let reqObj = {}
      reqObj.target = `/firmware/info/${firmwareType}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData != null) this.firmwareInfo[firmwareType] = result.msg.retData;
      }
    },

    closeUpdateCommandSend () {
      this.showUpdateDialog = false
    },
    async excuteUpdateCommandSend () {
      let reqObj = {}
      reqObj.target = `/sms/send`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "deviceID": this.deviceInfo.deviceID,
        "deviceMsisdn": this.deviceInfo.deviceMsisdn,
        "smsType": this.updateType === 'firmware' ? "Firmware" : "Wi-Fi"
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'전송했습니다.'})
        
        this.showUpdateDialog = false
      }
    },

    closeResetCommandSend () {
      this.showResetDialog = false
    },
    async excuteResetCommandSend () {
      let reqObj = {}
      reqObj.target = `/sms/send`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "deviceID": this.deviceInfo.deviceID,
        "deviceMsisdn": this.deviceInfo.deviceMsisdn,
        "smsType": "re-start"
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'전송했습니다.'})

        this.showResetDialog = false;
      }
    },

    async rebootDevice () {
      if(this.deviceInfo.deviceMsisdn == "00000000000") {
        this.chgIsAlert({status:true,string:'단말의 착신번호가 확인되지 않았습니다.'})
        
        return;
      }

      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 재시작 가능 합니다.`})
        return;
      }


      this.showResetDialog = true;
    },

    async initDeviceDetect () {
      this.showInitDetectListDialog = true
    },
    async submitInitDetectListDialog () {
      let reqObj = {}
      reqObj.target = `/device/detectInit/${this.$route.query.guid}`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'초기화했습니다.'})
        this.initDevice()
        this.showInitDetectListDialog = false
      }
    },
    closeInitDetectListDialog () {
      this.showInitDetectListDialog = false
    },

    refreshDeviceInfo () {
      this.getDeviceInfo(() => {
        console.log("refresh")
      })
    },
    async getDeviceInfo (callback) {
      let reqObj = {}
      reqObj.target = `/device/info/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(this.getCompanyGuid == '0' || this.getCompanyGuid == result.msg.retData.companyGuid) {
          this.deviceInfo = result.msg.retData;
          this.getMapInfo(this.deviceInfo.mapGuid,this.deviceInfo.pointX.replace("px",""),this.deviceInfo.pointY.replace("px",""))
          callback()
        }
        else {
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
          this.deviceInfo = {}
          this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})
          this.$router.push({name: 'HomePage'})
        }

      }
    },
    async getMapInfo (guid,pointX,pointY) {
      let reqObj = {}
      reqObj.target = `/map/info/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapInfo = result.msg.retData
       
        let img = new Image();
        let self = this;
        img.onload = function () {
          // let dispRateX = ((parseInt(pointX) - 15) / this.width) * 100
          // let dispRateY = ((parseInt(pointY) + 135) / this.height) * 100

          let dispRateX = ((parseInt(pointX) - 15) / this.width) * 100
          let dispRateY = ((parseInt(pointY) - 15) / this.height) * 100
          
          self.dispPointX = (dispRateX / 100) * self.$refs.mapAreaWrapper.clientWidth
          self.dispPointY = (dispRateY / 100) * self.$refs.mapAreaWrapper.clientHeight
          // self.dispPointX = Math.round((self.$refs.mapImage.clientWidth * pointX) / this.width)
          // self.dispPointY = Math.round((self.$refs.mapImage.clientHeight * pointY) / this.height)

          // console.log("원본", this.height, this.width)
          // console.log("변경", self.$refs.mapAreaWrapper.clientHeight, self.$refs.mapAreaWrapper.clientWidth)
          // console.log("위치", pointY, pointX)


          // if(self.dispPointY < 0) self.dispPointY = self.dispPointY + (100 - ((this.height / self.$refs.mapAreaWrapper.clientHeight) * 15))
          // self.dispPointY = self.dispPointY + ((this.height / self.$refs.mapImage.clientHeight) * 10)
        };
        img.src = this.mapInfo.fileContent;
      }
    },

    async getDetectList () {
      let reqObj = {}
      reqObj.target = `/detect/list/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {
        "offset": this.detectCurrentPage - 1,
        "limit": this.detectLimit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.detectList = result.msg.retData.content
        this.detectTotalCount = result.msg.retData.totalElements
      }
    },
    async getBlockList () {
      let reqObj = {}
      reqObj.target = `/block/list/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {
        "offset": this.blockCurrentPage - 1,
        "limit": this.blockLimit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.blockList = result.msg.retData.content
        this.blockTotalCount = result.msg.retData.totalElements
      }
    },
    async getDetectHistoryList () {
      let reqObj = {}
      reqObj.target = `/detect/history/list/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {
        "offset": this.detectHistoryCurrentPage - 1,
        "limit": this.detectHistoryLimit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.detectHistoryList = result.msg.retData.content
        this.detectHistoryTotalCount = result.msg.retData.totalElements
      }
    },

    detectChgCurrentPage (num) {
      this.detectCurrentPage = num
      this.getDetectList()
    },
    blockChgCurrentPage (num) {
      this.blockCurrentPage = num
      this.getBlockList()
    },
    detectHistoryChgCurrentPage (num) {
      this.detectHistoryCurrentPage = num
      this.getDetectHistoryList()
    },


    async chgDetectBlockDevice (item) {
      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 차단 가능 합니다.`})
        return;
      }

      this.blockActionInfo = item
      this.showBlockConfirmDialog = true
    },

    async chgBlockStatusDevice (item) {
      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 상태변경 가능 합니다.`})
        return;
      }

      this.blockActionInfo = item
      this.showBlockConfirmDialog = true
    },

    closeBlockConfirmDialog () {
      this.blockActionInfo = {}
      this.showBlockConfirmDialog = false
    },

    closeBlockCompleteDialog () {
      this.blockActionInfo = {}
      this.showBlockCompleteDialog = false
    },


    async chgDetectBlockDeviceAction () {
      let reqObj = {}
      reqObj.target = `/detect/block`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        detectSeq: this.blockActionInfo.detectSeq,
        deviceGuid : this.blockActionInfo.deviceGuid,
        detectMacAddress: this.blockActionInfo.detectMacAddress,
        detectIdx: this.blockActionInfo.detectIdx,
        detectVersion: this.blockActionInfo.detectVersion
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.getDetectList();
        this.getBlockList()
        this.showBlockConfirmDialog = false
        this.showBlockCompleteDialog = true
      }
    },
    async chgBlockStatusDeviceAction () {
      let reqObj = {}
      reqObj.target = `/block/${this.blockActionInfo.detectStatus === 'block'? 'clear' : 'block'}`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        blockGuid: this.blockActionInfo.blockGuid
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.getDetectList();
        this.getBlockList()
        this.showBlockConfirmDialog = false
        this.showBlockCompleteDialog = true
      }

    },

    async chgActionDevice (item) {
      this.blockActionInfo = item
      if(item.detectStatus === 'block') this.editDetectStatus = true
      else this.editDetectStatus = false

      this.blockMemo = '';
      this.blockAction = item.blockAction

      let reqObj = {}
      reqObj.target = `/block/memolist/${item.blockGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {}

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.blockMemoList = result.msg.retData
      }

      this.showActionDialog = true
    },
    closeActionDevice () {
      this.blockActionInfo = {}
      this.showActionDialog = false
    },

    async submitActionDevice () {
      let reqObj = {}
      reqObj.target = `/block/update`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "blockAction": this.blockAction,
        "blockGuid": this.blockActionInfo.blockGuid,
        "blockMemo": this.blockMemo,
        "blockType": this.editDetectStatus? 'block' : 'clear'
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.blockActionInfo = {}
        this.showActionDialog = false
        this.getBlockList()
      }
    },

    async dispBlockHistory (guid, mac) {
      let reqObj = {}
      reqObj.target = `/block/historylist/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {}

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.blockHistoryList = result.msg.retData
      }


      this.historyMacAddress = mac
      this.showBlockHistoryDialog = true
    },
    closeBlockHistory () {
      this.historyMacAddress = ''
      this.showBlockHistoryDialog = false
    },
    moveEventAdmin () {
      this.$router.push({name:"EventPage",query:{guid:this.deviceInfo.deviceGuid}})
    },
    moveDeviceLogAdmin () {
      this.$router.push({name:"DeviceAdmin",query:{guid:this.deviceInfo.deviceGuid}})
    },


    async getSendBlockList () {
      let reqObj = {}
      reqObj.target = `/block/sendlistcount/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.sendBlockListCount = result.msg.retData
      }
    },




    async displaySendBlockListDialog () {
      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 전송 가능 합니다.`})
        return;
      }

      // if(this.getUserRole === 'member' || this.getUserRole === 'agent') {
      if(this.getUserRole === 'agent') {
        this.chgIsAlert({status:true,string:'권한이 없습니다.'})
        return;
      }

      if(this.deviceInfo.deviceMsisdn == "00000000000") {
        this.chgIsAlert({status:true,string:'단말의 착신번호가 확인되지 않았습니다.'})
        return;
      }

      await this.getSendBlockList()

      // 차단 내역이 없어도 보내야 합니다. (innertron 요청 사항)
      // if(this.sendBlockListCount==0) {
      //   this.chgIsAlert({status:true,string:'전송할 차단내역이 없습니다.'})
      //   return;
      // }

      this.showSendBlockListDialog = true
    },
    closeSendBlockListDialog () {
      this.showSendBlockListDialog = false
    },
    async submitSendBlockListDialog () {
      let reqObj = {}
      reqObj.target = `/sms/send`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "deviceID": this.deviceInfo.deviceID,
        "deviceMsisdn": this.deviceInfo.deviceMsisdn,
        "smsType": "Block"
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'전송했습니다.'})
        this.showSendBlockListDialog = false

        this.getBlockList()
      }
    },

    dispDeleteBlockListDialog (guid) {
      this.deleteBlockGuid = guid
      this.showDeleteBlockListDialog = true
    },
    closeDeleteBlockListDialog () {
      this.deleteBlockGuid = ''
      this.showDeleteBlockListDialog = false
    },
    async submitDeleteBlockListDialog () {
      let reqObj = {}
      reqObj.target = `/block/delete`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = {
        "blockGuid": this.deleteBlockGuid
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'삭제 했습니다.'})
        this.showDeleteBlockListDialog = false
        this.getBlockList()
      }
    },

    async checkBlockStatus () {
      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 상태변경 가능 합니다.`})
        this.editDetectStatus = !this.editDetectStatus
        return;
      }

      console.log(this.editDetectStatus)
    },

    async excelDownload () {
      let url = `/detect/excelDownload/${this.deviceInfo.deviceGuid}`;
      let result = await requestGetDownload(url, "탐지 목록.xlsx");
      console.log(result)
    },
  },
  watch: {
    '$route.query.guid': function () {
      if(this.$route.query.guid == '' || this.$route.query.guid == null) {
        console.log("???")
      }
      else this.initDevice()
    },
    'getEventTimestamp': function () {
      console.log("Event Detect Child Page..." + this.getEventTimestamp)
      this.initDevice()
    },
    'getOnlineDevice': function () {
      let deviceGuid = this.getOnlineDevice.split("|@|")[0]
      if(deviceGuid == this.$route.query.guid) this.initDevice()
    },
    'getOfflineDevice': function () {
      let deviceGuid = this.getOfflineDevice.split("|@|")[0]
      if(deviceGuid == this.$route.query.guid) this.initDevice()
    },
  }
}
</script>
<style lang="">
  
</style>